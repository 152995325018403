import getParamsFromString from "./getParamsFromString"

export default (hashParams, url) => {
  let oldParams = getParamsFromString(url.hash)
  let hasAppended = false

  for (let entry of hashParams.entries()) {
    oldParams.append(entry[0], entry[1])
    hasAppended = true
  }

  if (hasAppended) {
    url.hash = "#" + oldParams.toString()
  }

  return url
}
