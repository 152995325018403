import redirectConfig from "../ssiConfigs/redirect"
import cookie from "js-cookie"

// Occurences of these in a destination definition will
// be filled with the real values
const EFID_REPLACEMENT = "${EFID}"
const LNG_REPLACEMENT = "${LNG}"

const { help, upsell, refer } = redirectConfig
const DESTINATIONS = {
  help,
  upsell,
  refer
}

const efidFromJwts = (jwts) => {
  if (jwts && jwts.account && jwts.account.userId) {
    return jwts.account.userId
  } else {
    return ""
  }
}

const getLng = () => {
  return cookie.get("lng") || ""
}

export default (key, jwts) => {
  if (!DESTINATIONS[key]) {
    return ""
  }

  return DESTINATIONS[key]
    .replace(EFID_REPLACEMENT, efidFromJwts(jwts))
    .replace(LNG_REPLACEMENT, getLng())
}
