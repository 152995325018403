import getParamsFromString from "./getParamsFromString"
import getDestination from "./getDestination"

const REDIRECT_URI = "redirect_uri"
const CUSTOM_DESTINATION = "destination"

const ALLOWED_PARAMS = {
  [REDIRECT_URI]: true,
  [CUSTOM_DESTINATION]: true
}

let options
let jwts

// This function adds support for optional params from the query string
// currently two kinds of optional redirect are supported: Local and Global
// * Local
//   - custom path, specified in redirect_uri query string
//   - must be on same domain
//   - e.g. /?redirect_uri=/account/dashboard
//   - goes to school.englishlive.ef.com/account/dashboard
// * GLobal
//   - key, specified in destination query string
//   - can go anywhere but is looked up from pre defined set in redirections ssi
//   - can add in EFID and Language to redirection
//   - e.g. ?destination=help
//   - can be configured to https://help.englishlive.ef.com/?EFID=34-3435-3434
export default (newJwts) => {
  if (options && jwts === newJwts) {
    return options
  }
  options = {}
  jwts = newJwts
  let params = getParamsFromString(window.location.search)

  for (let option of params.entries()) {
    if (!ALLOWED_PARAMS[option[0]]) {
      continue
    } else if (option[0] === CUSTOM_DESTINATION) {
      option[1] = getDestination(option[1], jwts)
    }
    options[option[0]] = option[1]
  }

  return options
}
