import "core-js/stable"
import "whatwg-fetch"
import languageChecker from "./languageChecker"
import redirect from "./redirect"

languageChecker()

redirect().then(null, (err) => {
  const spinner = document.querySelector(".spinner")
  const errorPanel = document.querySelector(".error-panel")
  const errorPanelMessage = document.querySelector(".error-panel--message")

  if (errorPanelMessage) {
    errorPanelMessage.textContent = err.toString()
  }

  if (errorPanel) {
    errorPanel.classList.add("visible")
  }

  if (spinner) {
    spinner.classList.add("hidden")
  }
})

