import { URLSearchParams } from "./url"
import removeLeadingHash from "./removeLeadingHash"

export default (hashString) => {
  let usp = new URLSearchParams(removeLeadingHash(hashString))

  return {
    append: (key, value) => {
      return usp.append(key, value)
    },
    entries: () => {
      return usp.entries()
    },
    toString: () => {
      return usp.toString()
    }
  }
}
