import { URL } from "./url"

export default (baseURL, url) => {
  let u = new URL(url, baseURL)
  return {
    toString: () => {
      return u.toString()
    },
    get hash () {
      return u.hash
    },
    set hash (value) {
      u.hash = value
    }
  }
}
