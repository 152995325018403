/*
  A shim layer to export the right implementation of
  URLSearchParams and URL for both node and browser
*/
import "url-search-params-polyfill"
const URLSearchParamsExport = URLSearchParams
const URLExport = URL

export { URLSearchParamsExport as URLSearchParams }
export { URLExport as URL }
